import { render, staticRenderFns } from "./LoginFooter.vue?vue&type=template&id=893e5d78&scoped=true"
import script from "./LoginFooter.vue?vue&type=script&lang=js"
export * from "./LoginFooter.vue?vue&type=script&lang=js"
import style0 from "styles/views/login/loginfooter.css?vue&type=style&index=0&id=893e5d78&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "893e5d78",
  null
  
)

export default component.exports